@config '../../../../tailwind.legacy.config.js';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/npm/fancygrid/client/fancy.css');

.fancy {
    opacity: 1!important;
}
